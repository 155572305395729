import React from "react";
import PropTypes from "prop-types";
import H1 from "./h1";

const Blog = ({ title, img, by, children }) => {
	return (
		<article>
			<H1 text={title} />
			<p className="text-grey-dark mb-6 text-center">by {by}</p>
			{img && (
				<div className="mb-6 mr-10 sm:float-left" style={{ width: "390px" }}>
					{img}
				</div>
			)}
			{children}
		</article>
	);
};

Blog.propTypes = {
	title: PropTypes.string.isRequired,
	by: PropTypes.string,
	img: PropTypes.node,
	children: PropTypes.node,
};

Blog.defaultProps = {
	img: null,
	by: "Advanced GI WA",
	children: null,
};

export default Blog;
