import React from "react";
import PropTypes from "prop-types";

const Par = ({ text, extraClasses, children }) => {
	return (
		<p className={`mb-6 mt-3 ${extraClasses}`}>
			{text}
			{children}
		</p>
	);
};

Par.propTypes = {
	text: PropTypes.string,
	extraClasses: PropTypes.string,
	children: PropTypes.node,
};

Par.defaultProps = {
	text: "",
	extraClasses: "",
	children: null,
};

export default Par;
