import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Haemochromatosis";

// Download link - http://www.fbrennangastro.com.au/downloads/gesahaemochromatosis.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "iron overload disease", "anaemia", "alcoholism"]}
			type="article"
			description="Having too much iron can cause Haemochromatosis - what that is, complications that come from it and diagnosing it"
		/>
		<H1>{title}</H1>
		<Par>
			Haemochromatosis is the most common form of iron overload disease. Primary or hereditary haemochromatosis is an
			inherited disease. Secondary haemochromatosis is caused by anaemia, alcoholism and other disorders.
		</Par>
		<Par>
			In haemochromatosis the body takes too much iron from the diet. The iron stores keep rising and, over time, the
			liver enlarges and becomes damaged leading to serious diseases such as cirrhosis. Other problems include heart
			disease, diabetes and arthritis.
		</Par>
		<Par>
			Estimates suggest one in 300 has the disease haemochromatosis. Both sexes are at risk, but women tend to develop
			the condition later in life.
		</Par>
		<Par>
			Haemochromatosis tends to be under-diagnosed, partly because its symptoms are similar to those caused by a range
			of other illnesses.
		</Par>
	</Blog>
);
