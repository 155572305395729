import PropTypes from "prop-types";
import React from "react";

const H1 = ({ text }) => {
	return <h1 className="h1">{text}</h1>;
};

H1.propTypes = {
	text: PropTypes.string.isRequired,
};

export default H1;
